<template>
  <div class="content-login">
    <!-- <div class="demoColorResponsive"></div> -->

    <img class="Img1" id="Img1" src="@/assets/ImgLogin/Enmascarar-grupo-1-new.png" />
    <img class="Img2" id="Img2" src="@/assets/ImgLogin/Enmascarar-grupo-2-new.png" />
    <img class="Img3" id="Img3" src="@/assets/ImgLogin/Enmascarar-grupo-3-new.png" />
    <div class="form-login">
      <div class="content-left">
        <img class="ImgLeft" src="@/assets/ImgLogin/img-left.png" />
      </div>
      <!-- :style="bMobile ? styleFormMobile : ''" -->
      <div class="content-right" >
          <form-login-component v-if="bLogin" @emitRotateImg="emitRotateImg" />
          <form-password-recovery-component v-else @emitRotateImg="emitRotateImg" />
      </div>
    </div>
  </div>
</template>

<script>
import FormLoginComponent from "./FormLoginComponent.vue";
import FormPasswordRecoveryComponent from "./FormPasswordRecoveryComponent.vue";

export default {
  components: { FormLoginComponent, FormPasswordRecoveryComponent },
  name: "LoginComponent",
  data() {
    return {
      screenHeight: 0,
      screenWidth: 0,
      bgColor: "red",
      bLogin: true,
      bMobile: false

    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize: function () {
      this.screenHeight = window.innerHeight;
      if (window.innerWidth > 600) {
        this.bMobile = false;
      } else {
        this.bMobile = true;
      }

    },
    emitRotateImg() {
      this.bLogin = !this.bLogin;
      var Img1 = document.getElementById("Img1");
      Img1.classList.toggle("Img1Rotate");

      var Img2 = document.getElementById("Img2");
      Img2.classList.toggle("Img2Rotate");

      var Img3 = document.getElementById("Img3");
      Img3.classList.toggle("Img3Rotate");

      // var ImgLimpieza = document.getElementById("ImgLimpieza");
      // ImgLimpieza.classList.toggle("ImgLimpiezaRotate");
    },
    openSofex() {
      window.open("https://www.sofex.com.mx");
    },
  },
  watch: {},
  computed: {
    styleFormMobile() {
      return {
        // "background-color": this.bgColor,
        height: `${this.screenHeight}px`,

      };
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.content-login {
  height: 100vh;
  background-color: var(--primary-background-color-login);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.form-login {
  display: flex;
  z-index: 3;
  height: 75%;
  width: 71%;
}

.content-left {
  height: 100%;
  width: 100%;
  position: relative;
  background-color: var(--primary-background-color-login);
  /* background: transparent linear-gradient(228deg, #ffc556 0%, #fffc81 100%) 0%
    0% no-repeat padding-box !important; */
  /* background-image: url("@/assets/ImgLogin/img-left.png"); */
  border-radius: 20px 0px 0px 20px;
}

.ImgLimpieza {
  position: absolute;
  width: 30vw;
  bottom: 1px;
  transition: 0.5s;
  left: 9vh;
}

.ImgLeft {
  width: 30vw;
  width: 100%;
  height: 100%;
  /* background-image: url("@/assets/ImgLogin/img-left.png"); */
  background-size: cover !important;
  object-fit: cover;
  border-radius: 18px 0px 0px 18px;
}

.ImgLimpiezaRotate {
  position: absolute;
  width: 30vw;
  bottom: 120px !important;
  transition: 0.5s;
  left: 9vh;
}

.content-right {
  height: 100%;
  width: 80%;
  background-color: #262c3a;
  border-radius: 0px 20px 20px 0px;
  overflow: auto;
}

.Img1 {
  position: fixed;
  height: 100%;
  left: -18vh;
  z-index: 9;
  transform: rotate(4deg);
  transition: 0.8s;
  bottom: 15px;
}

.Img1Rotate {
  position: absolute;
  height: 100%;
  left: -30vh !important;
  z-index: 1;
  transform: rotate(-13deg);
  transition: 0.8s;
  bottom: 0vh;
}

.Img2 {
  position: fixed;
  height: 56%;
  left: 10vh;
  transition: 0.8s;
  transform: rotate(4deg);
  z-index: 9;
  top: 11vh;
  bottom: 0px;
}

.Img2Rotate {
  position: absolute;
  height: 58%;
  left: -6vh !important;
  transform: rotate(-13deg);
  transition: 0.8s;
  z-index: 9;
  top: 7vh;
  bottom: 0px;
}

.Img3 {
  position: absolute;
  height: 90%;
  right: -50vh;
  transition: 0.8s;
  z-index: 1;
  top: 35vh;
  bottom: 0px;
}

.Img3Rotate {
  position: absolute;
  height: 90%;
  right: -50vh;
  transform: rotate(0.1turn);
  transition: 0.8s;
  z-index: 1;
  top: 53vh;
}

.demoColorResponsive {
  position: absolute;
  height: 1vh;
  width: 10vh;
  background-color: darkturquoise;
  top: 0px;
  bottom: 0px;
}

.footer-login {
  position: absolute;
  height: 1vh;
  width: 100%;
  text-align: center;
  color: var(--primary-color-text-white);
  /* background-color: darkturquoise; */
  /* top: 10px; */
  bottom: 5%;
  z-index: 3;
  font-size: 14px;
  font-family: "pop-Regular";
}

.text-sofex {
  color: var(--primary-color-footer-link);
  cursor: pointer;
  z-index: 3 !important;
  font-size: 14px;
  font-family: "pop-Regular";
}

/* RESPONSIVE CSS */
/* XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }

  .content-login {
    height: 100vh;
    background-color: var(--primary-background-color-login);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
  }

  .form-login {
    display: flex;
    z-index: 3;
    height: 100%;
    width: 90vw;
  }

  .content-left {
    display: none;
  }

  .content-right {
    height: auto;
    /* max-height: 100px; */
    min-height: auto;
    margin-top: 10vh;
    margin-bottom: 10vh;
    width: 100%;
    background-color: var(--primary-background-color-form-login);
    border-radius: 20px;
    overflow: auto;
  }

  .Img1 {
    position: fixed;
    height: 100%;
    left: -13vh;
    z-index: 9;
    transform: rotate(4deg);
    transition: 0.8s;
    bottom: 0px;
    display: none !important;
  }

  .Img1Rotate {
    position: absolute;
    height: 100%;
    left: -30vh !important;
    z-index: 1;
    transform: rotate(-13deg);
    transition: 0.8s;
    bottom: 0vh;
    display: none !important;
  }

  .Img2 {
    position: fixed;
    height: 58%;
    left: 14vh;
    transition: 0.8s;
    transform: rotate(4deg);
    z-index: 9;
    top: 12vh;
    bottom: 0px;
    display: none !important;
  }

  .Img2Rotate {
    position: absolute;
    height: 58%;
    left: -6vh !important;
    transform: rotate(-13deg);
    transition: 0.8s;
    z-index: 9;
    top: 7vh;
    bottom: 0px;
    display: none !important;
  }

  .Img3 {
    position: absolute;
    height: 90%;
    right: -50vh;
    transition: 0.8s;
    z-index: 1;
    top: 35vh;
    bottom: 0px;
    display: none !important;
  }

  .Img3Rotate {
    position: absolute;
    height: 90%;
    right: -50vh;
    transform: rotate(0.1turn);
    transition: 0.8s;
    z-index: 1;
    top: 53vh;
    display: none !important;
  }
}

/* SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(91, 113, 236);
  }

  .content-left {
    display: none;
  }

  .content-right {
    height: 100%;
    width: 100%;
    background-color: #262c3a;
    border-radius: 20px;
    overflow: auto;
  }

  .Img1 {
    position: fixed;
    height: 100%;
    left: -13vh;
    z-index: 9;
    transform: rotate(4deg);
    transition: 0.8s;
    bottom: 0px;
    display: none !important;
  }

  .Img1Rotate {
    position: absolute;
    height: 100%;
    left: -30vh !important;
    z-index: 1;
    transform: rotate(-13deg);
    transition: 0.8s;
    bottom: 0vh;
    display: none !important;
  }

  .Img2 {
    position: fixed;
    height: 58%;
    left: 14vh;
    transition: 0.8s;
    transform: rotate(4deg);
    z-index: 9;
    top: 12vh;
    bottom: 0px;
    display: none !important;
  }

  .Img2Rotate {
    position: absolute;
    height: 58%;
    left: -6vh !important;
    transform: rotate(-13deg);
    transition: 0.8s;
    z-index: 9;
    top: 7vh;
    bottom: 0px;
    display: none !important;
  }

  .Img3 {
    position: absolute;
    height: 90%;
    right: -50vh;
    transition: 0.8s;
    z-index: 1;
    top: 35vh;
    bottom: 0px;
    display: none !important;
  }

  .Img3Rotate {
    position: absolute;
    height: 90%;
    right: -50vh;
    transform: rotate(0.1turn);
    transition: 0.8s;
    z-index: 1;
    top: 53vh;
    display: none !important;
  }
}

/* MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }

  .ImgLimpieza {
    position: absolute;
    width: 30vw;
    bottom: 1px;
    transition: 0.5s;
    left: 5.5vh;
  }

  .ImgLimpiezaRotate {
    position: absolute;
    width: 30vw;
    bottom: 120px !important;
    transition: 0.5s;
    left: 5.5vh;
  }

  .Img1 {
    position: fixed;
    height: 77%;
    left: -12vh;
    z-index: 9;
    transform: rotate(4deg);
    transition: 0.8s;
    top: -9px;
  }

  .Img1Rotate {
    position: absolute;
    height: 77%;
    left: -25vh !important;
    z-index: 1;
    transform: rotate(-13deg);
    transition: 0.8s;
    bottom: 0vh;
  }

  .Img2 {
    position: fixed;
    height: 44%;
    left: 9.4vh;
    transition: 0.8s;
    transform: rotate(4deg);
    z-index: 9;
    top: 8.5vh;
    bottom: 0px;
  }

  .Img2Rotate {
    position: absolute;
    height: 44%;
    left: -6.5vh !important;
    transform: rotate(-13deg);
    transition: 0.8s;
    z-index: 9;
    top: 5vh;
    bottom: 0px;
  }

  .Img3 {
    position: absolute;
    height: 90%;
    right: -50vh;
    transition: 0.8s;
    z-index: 1;
    top: 35vh;
    bottom: 0px;
  }

  .Img3Rotate {
    position: absolute;
    height: 90%;
    right: -50vh;
    transform: rotate(0.1turn);
    transition: 0.8s;
    z-index: 1;
    top: 53vh;
  }
}

/* LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }

  .ImgLimpieza {
    position: absolute;
    width: 30vw;
    bottom: 1px;
    transition: 0.5s;
    left: 7vh;
  }

  .ImgLimpiezaRotate {
    position: absolute;
    width: 30vw;
    bottom: 120px !important;
    transition: 0.5s;
    left: 7vh;
  }
}

/* XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}
</style>
