<template>
  <div class="content-form-password-recovery">
    <div class="content-img-logo-recamsa">
      <img
        class="img-logo-recamsa"
        src="@/assets/ImgLogin/mini-logo-recamsa-white.png"
      />
    </div>
    <div class="content-recovery-password">
      <p class="text-recovery-password pop-Bold">Recuperación de contraseña</p>
    </div>
    <div class="content-text-subtext">
      <p class="text-subtext pop-Regular">
        Ingresa el correo electrónico registrado en Recamsa para poder indicarte
        los pasos a seguir para la recuperación.
      </p>
    </div>
    <div class="content-field-email">
      <v-text-field
        v-model="sEmail"
        label="Correo electrónico"
        placeholder="Correo electrónico..."
        class="global-text-field-login"
        color="var(--primary-color-border-input)"
        background-color="var(--primary-color-menu)"
        persistent-placeholder
        outlined
      ></v-text-field>
    </div>
    <div class="content-btn-send">
      <v-btn
        class="global-btn-primary btn-send"
        @click="recoveryPassword()"
        :disabled="!validateForm"
      >
        Enviar
      </v-btn>
    </div>
    <div class="content-return">
      <v-btn
        class="global-btn-second-white btn-back"
        @click="$emit('emitRotateImg')"
      >
        Regresar
      </v-btn>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      show1: false,
      sEmail: "",
    };
  },
  methods: {
    rotateImg() {
      this.$emit("emitRotateImg");
    },
    recoveryPassword() {
      this.loadingForm = true;

      const payload = {
        sEmail: this.sEmail,
      };

      DB.post(`${URI}/api/sp/v1/auth/recovery`, payload)
        .then((response) => {
          this.loadingForm = false;

          this.Success(response.data.message);
          this.sEmail = "";
        })
        .catch((error) => {
          this.loadingForm = false;
          this.Error(error.response.data);
        });
    },
  },
  computed: {
    validateForm() {
      return this.sEmail.trim() !== "";
    },
  },
};
</script>
<style  scoped>
.content-form-password-recovery {
  height: 100%;
  justify-self: center;
  padding: 60px 10px 10px 10px;
}
.content-img-logo-recamsa {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10vh;
  margin-bottom: 15px;
}
.img-logo-recamsa {
  width: 20vw;
  margin-top: 30px;
}
.content-field-email {
  padding: 0px 20px 0px 20px;
}
.content-btn-send {
  padding: 0px 20px 0px 20px;
  margin-bottom: 15px;
}

.content-btn-send .theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: var(--primary-color-btn-primary) !important;
}
.content-return {
  padding: 5px 20px 5px 20px;
  margin-bottom: 15px;
}
.text-recovery-password {
  font-size: 1.5rem;
  text-align: center;
  color: #ffffffff;
  margin-bottom: 15px;
  margin-top: 30px;
}
.text-subtext {
  font-size: 0.8rem;
  color: #ffffffff;
  text-align: center;
  margin-bottom: 25px;
}

.text-return {
  color: #ffffffff;
  text-decoration: underline;
  cursor: pointer;
}
.btn-send {
  height: 3rem !important;
}
.btn-back {
  height: 3rem !important;
}
/**************************MODO RESPONSIVO ********************/
/* XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }

  .img-logo-recamsa {
    width: 55vw;
    margin-top: 30px;
  }
  .text-recovery-password {
    font-size: 5vw;
    font-family: "pop-Bold";
    text-align: center;
    color: var(--primary-color-text-white);
    margin-top: 30px;
  }
  /* .img-logo-recamsa {
    width: 15rem;
    margin-top: 30px;
  }
  .text-recovery-password {
    font-size: 1.6rem;
    font-family: "pop-Bold";
    text-align: center;
    color: var(--primary-color-text-white);
    margin-top: 40px;
    margin-bottom: 40px;
  } */
}

/* SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(91, 113, 236);
  }

  .img-logo-recamsa {
    width: 45vw;
    margin-top: 30px;
  }
  .text-recovery-password {
    font-size: 4vw;
    font-family: "pop-Bold";
    text-align: center;
    color: var(--primary-color-text-white);
    margin-top: 30px;
  }
  /* .img-logo-recamsa {
    width: 25rem;
    margin-top: 30px;
  }
  .text-recovery-password {
    font-size: 2rem;
    font-family: "pop-Bold";
    text-align: center;
    color: var(--primary-color-text-white);
    margin-top: 40px;
    margin-bottom: 40px;
  } */
}

/* MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
  .img-logo-recamsa {
    width: 25vw;
    margin-top: 30px;
  }
  .text-recovery-password {
    font-size: 2vw;
    font-family: "pop-Bold";
    text-align: center;
    color: var(--primary-color-text-white);
    margin-top: 30px;
  }
}

/* LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
  /* .img-logo-recamsa {
    width: 17rem;
    margin-top: 30px;
  } */
  /* .content-img-logo-recamsa {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 85px;
  }
  .img-logo-recamsa {
    width: 300px;
    margin-bottom: 10px;
  }
  .text-Login {
    font-size: 25px;
    font-family: "pop-Bold";
    text-align: center;
    color: var(--primary-color-text-white);
  }
  .content-field-email {
    margin-bottom: 5px !important;
  }
  .btn-login {
    height: 3rem !important;
  }
  .content-problem-login {
    margin-top: 25px;
    text-align: center;
  }
  .text-problem-login {
    color: var(--primary-color-text-white);
    text-decoration: underline;
    cursor: pointer;
  } */
}

/* XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
  /* .content-form-login {
    height: 100%;
    justify-self: center;
    padding: 10px;
  }
  .content-img-logo-recamsa {
    display: flex;
    justify-content: center;
    justify-self: center;
    align-items: center;
    height: 10vh;
  }
  .img-logo-recamsa {
    width: 30rem;
  }
  .text-Login {
    font-size: 2.5rem;
    font-family: "pop-Bold";
    text-align: center;
    color: var(--primary-color-text-white);
  }
  .btn-login {
    height: 3rem !important;
  }
  .content-problem-login {
    margin-top: 20px;
    text-align: center;
  }
  .text-problem-login {
    color: var(--primary-color-text-white);
    text-decoration: underline;
    cursor: pointer;
  } */
}
</style>