<template>
    <login-component />
</template>

<script>
import LoginComponent from "@/components/login/LoginComponent.vue";

export default {
  name: "LoginView",
  data(){
    return{
      screenHeight:0,
    }
  },
  created() {
    window.addEventListener("resize",this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize",this.handleResize);
  },
  methods:{
      handleResize() {
      this.screenHeight = window.innerHeight ;
    },
  },
  components: {
    LoginComponent,
  },
};
</script>