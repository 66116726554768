<template>
  <div class="content-form-login">
    <div class="content-img-logo-recamsa">
      <img class="img-logo-recamsa" src="@/assets/ImgLogin/mini-logo-recamsa-white.png" />
    </div>
    <p class="text-Login">Inicio de sesión</p>
    <div class="content-field-email">
      <v-text-field v-model="sEmail" ref="textEmail" label="Correo electrónico" placeholder="Correo electrónico..."
        persistent-placeholder class="global-text-field-login" color="var(--primary-color-border-input)"
        background-color="var(--primary-color-menu)" outlined @keypress="focusTextPassowrd"></v-text-field>
    </div>
    <div class="content-field-password">
      <v-text-field v-model="sPassword" ref="textPassword" class="global-text-field-login"
        color="var(--primary-color-border-input)" background-color="var(--primary-color-menu)"
        prepend-inner-icon="mdi-lock" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
        :type="show1 ? 'text' : 'password'" label="Contraseña" placeholder="********" outlined persistent-placeholder
        @click:append="show1 = !show1" @keypress="preLogin"></v-text-field>
    </div>
    <div class="content-btn-login">
      <v-btn class="btn-login global-btn-primary" :loading="loadingForm" @click="login" :disabled="!validateForm">
        Iniciar sesión
      </v-btn>
    </div>
    <div class="content-problem-login">
      <p class="text-problem-login" @click="rotateImg()">
        ¿Problemas para iniciar sesión?
      </p>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      show1: false,
      loadingForm: false,
      sEmail: "",
      sPassword: "",
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
        emailMatch: () => `The email and password you entered don't match`,
      },

    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize: function () {
      this.screenHeight = window.innerHeight - 20;


    },
    preLogin: function ($event) {
      if ($event.code == "Enter") this.login();
    },
    focusTextPassowrd(e) {
      var keycode = e.keyCode ? e.keyCode : e.which;
      if (keycode == "13") {
        this.$refs.textPassword.focus();

        e.preventDefault();
        return false;
      }
    },
    login() {
      if (this.sEmail !== "" && this.sPassword !== "") {
        this.loadingForm = true;
        const payload = {
          sEmail: this.sEmail,
          sPassword: this.sPassword,
        };
        //post credentials and get access token from laravel backend
        DB.post(`${URI}/api/sp/v1/auth/`, payload)
          .then((response) => {
            this.$store
              .dispatch(
                "setPermissionsBtnGlobal",
                response.data.results.aUserPermissions
              )
              .then((resp) => {
                response.data.results["sUrlImg"] =
                  response.data.results.sProfilePicture.length > 0 ? response.data.results.sProfilePicture[1].sUrl : require("@/assets/Empty_Profile.png");
                this.$store.commit("setInformationUser", response.data.results);
                this.Success(response.data.message);
                setTimeout(() => {
                  this.$router.go();
                }, 1000);
              })
              .catch((err) => {
                this.Error(err);
              });
          })
          .catch((error) => {
            this.loadingForm = false;
            this.Error(error.response.data);
          });
      }
    },
    rotateImg() {
      this.$emit("emitRotateImg");
    },
  },
  computed: {
    validateForm() {
      return this.sEmail.trim() !== "" && this.sPassword.trim() !== "";
    },
  },
};
</script>
<style  scoped>
.content-form-login {
  height: 100%;
  justify-self: center;
  padding: 60px 10px 10px 10px;
}

.content-img-logo-recamsa {
  display: flex;
  justify-content: center;
  justify-self: center;
  align-items: center;
  height: 10vh;
}

.img-logo-recamsa {
  width: 20vw;
  margin-top: 30px;
}

.text-Login {
  font-size: 1.5vw;
  font-family: "pop-Bold";
  text-align: center;
  color: var(--primary-color-text-white);
  margin-top: 30px;
  margin-bottom: 30px;
}

.content-field-email {
  padding: 5px 20px 5px 20px;
}

.content-field-password {
  padding: 5px 20px 5px 20px;
}

.content-btn-login {
  padding: 5px 20px 5px 20px;
}

.content-btn-login .theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: var(--primary-color-btn-primary) !important;
}

.btn-login {
  height: 3rem !important;
}

.content-problem-login {
  margin-top: 80px;
  text-align: center;
}

.text-problem-login {
  color: var(--primary-color-text-white);
  text-decoration: underline;
  cursor: pointer;
  font-family: "pop-Regular";
}

/**************************MODO RESPONSIVO ********************/
/* XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }

  .content-form-login {
    height: 100%;
    justify-self: center;
    padding: 10px 10px 10px 10px;
  }

  .img-logo-recamsa {
    width: 55vw;
    margin-top: 30px;
  }

  .text-Login {
    font-size: 5vw;
    font-family: "pop-Bold";
    text-align: center;
    color: var(--primary-color-text-white);
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .content-problem-login {
    margin-top: 60px;
    text-align: center;
  }
}

/* SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(91, 113, 236);
  }

  .img-logo-recamsa {
    width: 45vw;
    margin-top: 30px;
  }

  .text-Login {
    font-size: 4vw;
    font-family: "pop-Bold";
    text-align: center;
    color: var(--primary-color-text-white);
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

/* MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }

  .img-logo-recamsa {
    width: 25vw;
    margin-top: 30px;
  }

  .text-Login {
    font-size: 2vw;
    font-family: "pop-Bold";
    text-align: center;
    color: var(--primary-color-text-white);
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

/* LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }

  .img-logo-recamsa {
    width: 20vw;
    margin-top: 30px;
  }

  .text-Login {
    font-size: 1.5vw;
    font-family: "pop-Bold";
    text-align: center;
    color: var(--primary-color-text-white);
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

/* XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}
</style>